
const BASE_URL = "https://dev.skillsbridge.ai/api"; // Base URL for API

export const API_ENDPOINTS = {
  ROLES: `${BASE_URL}/roles`,
  SB_JOB_DESCRIPTION: `${BASE_URL}/sb_job_description`,
  AUTOMATION_POTENTIAL: `${BASE_URL}/automation_potential`,
  COMPARE_DATA: `${BASE_URL}/compare_data`,
  UPLOAD: `${BASE_URL}/upload`,
  ANALYZE_JD: `${BASE_URL}/analyze_jd`,
  // Add more endpoints here as needed
};
