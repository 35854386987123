import React, { useRef, useState, useContext, useEffect } from "react";
import MainContent from "../../../../Components/HRTabsMainContent.jsx";
import ScrollableBox from "../../../../Components/ScrollableBox";
import JobDetail from "./JobDetails.jsx";
import { jobTabContent } from "../../../../Assets/HRStaticContent.js";
import { AuthContext } from "../../../../State/AuthContext.js";
import { useSelection } from "../../../../Hooks/TabsStateContext.js";
import { useNotification } from "../../../../State/NotificationContext";
import { API_ENDPOINTS } from "../../../../config/ApiConfig"; 


const JobsTab = ({ jobData }) => {
  const { triggerNotification } = useNotification();
  const { selectedJob, setSelectedJob, selectedItem, setSelectedItem } =
    useSelection();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const { email } = useContext(AuthContext);

  const handleJobSelect = (job) => {
    setSelectedJob(job);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
      uploadFile(file);
    } else {
      alert("Please select a PDF file.");
    }
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("email", email);

    fetch(API_ENDPOINTS.UPLOAD, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("File upload response:", data);
        setAnalysisResult(data.analysis_result);
        triggerNotification("File uploaded successfully!");
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        triggerNotification("Error uploading file!");
      });
  };

  const analyzeJobDescription = (prompt) => {
    fetch(API_ENDPOINTS.ANALYZE_JD, {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: prompt }), // Send the prompt in the body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response:", data);
        setAnalysisResult(data); // Set the analysis result for displaying
        triggerNotification("API response received successfully!");
      })
      .catch((error) => {
        console.error("Error analyzing job description:", error);
        triggerNotification(
          `Error analyzing job description: ${error.message}`
        );
      });
  };

  useEffect(() => {
    if (!selectedItem) {
      setSelectedJob(null);
    }
  }, [selectedItem, setSelectedJob]);

  const handleAnalyzeClick = () => {
    const prompt =
      "write the job description of the airport duty manager. Please provide output in json format";
    analyzeJobDescription(prompt);
  };

  if (!selectedItem || !selectedJob) {
    return (
      <div className="jobs-tab">
        <MainContent customText={jobTabContent} onJobSelect={handleJobSelect} />
        {selectedItem && (
          <div className="file-upload-section">
            <input
              type="file"
              accept="application/pdf"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <button onClick={handleUploadClick} className="upload-btn">
              Upload JD
            </button>
            <button onClick={handleAnalyzeClick} className="analyze-btn">
              Analyze JD
            </button>
            {analysisResult && (
              <ScrollableBox
                height="300px"
                width="100%"
                content={<pre>{JSON.stringify(analysisResult, null, 2)}</pre>}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return <div className="jobs-tab">{<JobDetail jobId={selectedJob.id} />}</div>;
};

export default JobsTab;
