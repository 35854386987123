import React, { useState } from "react";
import MainContent from "../../../Components/EmployeeTabsMainContent"; // Import MainContent
import { skillsTabContent } from "../../../Assets/EmployeeStaticContent"; // Import the content

const SkillsTab = () => {
  const [prompt, setPrompt] = useState(""); // State to hold the user's input
  const [apiResponse, setApiResponse] = useState(""); // State to hold the API response
  const [loading, setLoading] = useState(false); // State for loading status
  const [error, setError] = useState(null); // State to capture errors

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission from reloading the page

    setLoading(true); // Show loading state
    setError(null); // Clear any previous errors

    try {
      // Make an API call with the user's prompt
      const response = await fetch("http://127.0.0.1:8080/api/submit-prompt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt }), // Send the prompt in the request body
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }

      const data = await response.json();
      setApiResponse(data.response); // Assume the API response contains a "response" field
    } catch (err) {
      setError(err.message); // Set error message
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  return (
    <div className="progress-tab">
      {/* Display the existing content */}
      <MainContent customText={skillsTabContent} />

      {/* Form to input the prompt */}
      <div className="prompt-input-section">
        <h3>Submit a Prompt</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)} // Update the prompt value on input change
            placeholder="Enter your prompt"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>

      {/* Display the response or loading/error states */}
      <div className="response-section">
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {apiResponse && (
          <div className="api-response">
            <h4>API Response:</h4>
            <p>{apiResponse}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SkillsTab;

// import React, { useState } from "react";
// import MainContent from "../../MainContent";
// import JobDetail from "./SkillsDetails"; // Import JobDetail

// const JobsTab = () => {
//   const [selectedJob, setSelectedJob] = useState(null);

//   // Custom text for the 'jobs' tab
//   const customText =
//     "View Skill Gaps for";

//   const handleJobSelect = (job) => {
//     setSelectedJob(job);
//   };

//   return (
//     <div className="jobs-tab">
//       {!selectedJob ? (
//         <MainContent customText={customText} onJobSelect={handleJobSelect} />
//       ) : (
//         <JobDetail job={selectedJob} />
//       )}
//     </div>
//   );
// };

// export default JobsTab;

// import React from "react";
// import MainContent from "../MainContent"; // Import MainContent
// import { skillsTabContent } from "../EmployeeStaticContent"; // Import the content

// const SkillsTab = () => {
//   return (
//     <div className="progress-tab">
//       {/* Pass the entire skillsTabContent object */}
//       <MainContent customText={skillsTabContent} />
//     </div>
//   );
// };

// export default SkillsTab;
