import React from 'react';
import './EmployeeTabsMainContent.css';

const EmployeeTabMainContent = ({ customText }) => {
  // Destructure with default values
  const { title = '', paragraphs = [] } = customText || {};

  return (
    <div className="page-container">
      <div className="text-content"> 
        {/* Display the custom text passed from the parent component */}
        <h1>{title}</h1>
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <div className="scrollable-content-box">
        {/* Add content inside the scrollable box */}
        <p>Scrollable Content Line 1</p>
        <p>Scrollable Content Line 2</p>
        <p>Scrollable Content Line 3</p>
        {/* Add more scrollable content as needed */}
      </div>
    </div>
  );
};

export default EmployeeTabMainContent;
