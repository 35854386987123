import React, { createContext, useContext, useState } from "react";

// Create NotificationContext
const NotificationContext = createContext();

// Export NotificationProvider to wrap your app
export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);

  const triggerNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification(null); // Clear notification after 5 seconds
    }, 5000);
  };

  return (
    <NotificationContext.Provider value={{ notification, triggerNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Export the custom hook to use NotificationContext
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
