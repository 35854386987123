// src/components/SelectRole.js
import React, { useState } from "react";
import "./SelectRole.css";

const SelectRole = ({ placeholder, roles, onRoleSelect }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleGoClick = () => {
    if (selectedRole) {
      onRoleSelect(selectedRole); // Call the callback to return the selected role
    }
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    setSearchQuery(role.name); // Set the input to the selected role
    onRoleSelect(role); // Call the callback to return the selected role
  };

  // Filter roles based on the search query
  const filteredRoles = roles.filter((role) =>
    role.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="select-role-container">
      <div className="search-bar">
        <input
          type="text"
          className="search-input"
          placeholder={placeholder || "Search for a role..."}
          value={searchQuery}
          onChange={handleInputChange}
        />
        <button className="go-button" onClick={handleGoClick}>
          Go
        </button>
      </div>
      {filteredRoles.length > 0 && (
        <ul className="role-list">
          {filteredRoles.map((role) => (
            <li
              key={role.id}
              onClick={() => handleRoleClick(role)}
              className={`role-item ${
                selectedRole?.id === role.id ? "selected" : ""
              }`}
            >
              {role.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectRole;
