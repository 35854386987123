import React, { useEffect, useState } from 'react';
import './HRTabsMainContent.css';
import { useSelection } from '../Hooks/TabsStateContext';
import DropdownMenu from './DropdownMenu'; 
import SelectRole from './SelectRole'; 
import { API_ENDPOINTS } from '../config/ApiConfig';


function MainContent({ onJobSelect, customText }) {
  const { selectedItem, setSelectedItem, searchQuery, setSearchQuery } = useSelection();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(API_ENDPOINTS.ROLES)
      .then(response => response.json())
      .then(data => {
        console.log("Fetched Data:", data);
        setData(data); 
      })
      .catch(error => console.error('Error fetching job data:', error));
  }, []);

  const handleJobClick = (item) => {
    onJobSelect(item);
  };

  const dropdownItems = [
    { value: "level", label: "Level" },
    { value: "role", label: "Role" },
  ];

  return (
    <div className="main-content">
      <div className="dropdown-section">
        <h1>{customText.title}</h1>
        <DropdownMenu 
          options={dropdownItems}
          placeholder="Select an option"
          value={selectedItem} // Pass down selectedItem as prop
          onItemSelect={(item) => {
            setSelectedItem(item); // Update selectedItem in context
            console.log('Selected Item:', item);
          }}
        />
      </div>

      {!selectedItem && (
        <div className="content-section">
          {Object.entries(customText).map(([key, value], index) => {
            if (index !== 0) {
              if (Array.isArray(value)) {
                return (
                  <div key={key}>
                    <ul>
                      {value.map((item, idx) => (
                        <p key={idx}>{item}</p>
                      ))}
                    </ul>
                  </div>
                );
              } else if (typeof value === 'string') {
                return (
                  <p key={key}>{value}</p>
                );
              }
            }
            return null;
          })}
        </div>
      )}

      {selectedItem && (
        <div>
          <SelectRole 
            placeholder="Select a role"
            roles={Array.isArray(data) ? data.map(item => ({ id: item.id, name: item["Position Title"] })) : []}
            onRoleSelect={(role) => {
              console.log('Selected Role:', role);
              handleJobClick(role);
            }} 
          />
        </div>
      )}
    </div>
  );
}

export default MainContent;