import React, { useState, useEffect } from 'react';
import JobDetailChart from './JobDonutChart.js';
import AccountabilityChart from './JobBarChart.js';
import Draggable from 'react-draggable'; // Ensure react-draggable is imported
import { ResizableBox } from 'react-resizable'; // Import ResizableBox
import { jobTabContent } from '../../../../Assets/HRStaticContent.js'; // Import the content
import { useSelection } from "../../../../Hooks/TabsStateContext.js";
import DropdownMenu from '../../../../Components/DropdownMenu.js'; 
import ScrollableBox from '../../../../Components/ScrollableBox.js'; // Import the ScrollableBox component
import JDDiffChecker from '../../../../Components/JDDiffChecker.js'; 
import { API_ENDPOINTS } from '../../../../config/ApiConfig'; 

import './JobDetails.css';

function JobDetail({ jobId }) {
    
  const { selectedJob, setSelectedJob, selectedItem, setSelectedItem } = useSelection();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [sortedAccountabilities, setSortedAccountabilities] = useState([]);

  const [isJDModalOpen, setJDModalOpen] = useState(false);
  const [isCompareModalOpen, setCompareModalOpen] = useState(false);
  const [jdData, setJDData] = useState(null);
  const [compareData, setCompareData] = useState(null);

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        setLoading(true);
        const response = await fetch(API_ENDPOINTS.AUTOMATION_POTENTIAL);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setJob(data);

        // Extract the min and max values from the overall role automation potential range
        const [minPotential, maxPotential] = data.overall_role_automation_potential
          .split('-')
          .map((val) => parseFloat(val));

        // Calculate the average of the min and max values
        const averageAutomation = ((minPotential + maxPotential) / 2).toFixed(2);
        const unaffectedPercentage = (100 - averageAutomation).toFixed(2);
        setChartData([averageAutomation, unaffectedPercentage]);

        // Sort accountabilities by automation potential
        const sorted = data.accountability_automation_potential.sort(
          (a, b) => parseFloat(b.automation_potential) - parseFloat(a.automation_potential)
        );
        setSortedAccountabilities(sorted);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [jobId]);

  const fetchJDData = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.SB_JOB_DESCRIPTION);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setJDData(data);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchCompareData = async () => {
    try {
      const response = await fetch(API_ENDPOINTS.COMPARE_DATA);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCompareData(data.comparison);
    } catch (error) {
      setError(error.message);
    }
  };

  const dropdownItems = [
    { value: "level", label: "Level" },
    { value: "role", label: "Role" },
  ];

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!job) return <div>No job data available</div>;

  return (
    <div className="job-detail">
      <div className="dropdown-section">
        <h1>{jobTabContent.title}</h1>
        <DropdownMenu 
          options={dropdownItems}
          placeholder="Select an option"
          value={selectedItem} // Pass down selectedItem as prop
          onItemSelect={(item) => {
            setSelectedItem(item); // Update selectedItem in context

            if (item === null || item.value === "") {
              setSelectedJob(null); // Set selectedJob to null if no item is selected
            } else {
              console.log('Selected Item:', item);
              // Logic to update selectedJob based on item
              // You can add logic here to update selectedJob based on selected item
            }
          }}
        />
      
      </div>

      <div className="search-section">
        <input 
          type="text" 
          placeholder={selectedJob.name} 
        />
      </div>

      <div className="content-layout">
  <div className="content-box-wrapper">
    {/* ScrollableBox for Job Automation Donut Chart */}
      <ScrollableBox
        height="500px" // Set appropriate height for your layout
        width="100%"
        content={
          <>
            <div className="button-group">
              <button onClick={() => { setJDModalOpen(true); fetchJDData(); }}>View SB.AI JD</button>
              <button onClick={() => { setCompareModalOpen(true); fetchCompareData(); }}>Compare</button>
            </div>
            <h4>Job automation possible with current tech</h4>
            <div className="chart-box-donut">
              {chartData && <JobDetailChart data={chartData} />}
            </div>
          </>
        }
      />
      <p className="box-description">Key for the donut chart box</p>
    </div>

    <div className="content-box-wrapper">
      {/* ScrollableBox for Accountability Chart */}
      <ScrollableBox
        height="500px" // Set appropriate height for your layout
        width="100%"
        content={
          <>
            <h4>Job automation possible with current technology, by key accountabilities (average across selection)*</h4>
            {sortedAccountabilities.map((item, index) => (
              <div key={index} className="chart-item">
                <p>{item.accountability}</p>
                <div className="chart-box">
                  <AccountabilityChart automationPotential={parseFloat(item.automation_potential)} />
                </div>
              </div>
            ))}
          </>
        }
      />
      <p className="box-description">Key for the bar chart box</p>
    </div>
  </div>

      {/* <div className="content-layout">
        <div className="content-box-wrapper">
          <div className="content-box">
            <div className="button-group">
              <button onClick={() => { setJDModalOpen(true); fetchJDData(); }}>View SB.AI JD</button>
              <button onClick={() => { setCompareModalOpen(true); fetchCompareData(); }}>Compare</button>
            </div>

            <h4>Job automation possible with current tech</h4>
            <div className="chart-box-donut">
              {chartData && <JobDetailChart data={chartData} />}
            </div>
          </div>
          <p className="box-description">Key for the donut chart box</p>
        </div>

        <div className="content-box-wrapper">
          <div className="content-box">
            <h4>Job automation possible with current technology, by key accountabilities (average across selection)*</h4>
            {sortedAccountabilities.map((item, index) => (
              <div key={index} className="chart-item">
                <p>{item.accountability}</p>
                <div className="chart-box">
                  <AccountabilityChart automationPotential={parseFloat(item.automation_potential)} />
                </div>
              </div>
            ))}
          </div>
          <p className="box-description">Key for the bar chart box</p>
        </div>
      </div> */}

      {/* Modal for View SB.AI JD */}
      {isJDModalOpen && (
        <Draggable>
          <ResizableBox
            width={300}
            height={300}
            minConstraints={[150, 150]}
            maxConstraints={[800, 600]}
            className="modal modal-left"
          >
            <div className="modal-content">
              <h2>SB.AI JD Data</h2>
              {jdData ? <div>{JSON.stringify(jdData)}</div> : <div>Loading...</div>}
              <button onClick={() => setJDModalOpen(false)}>Close</button>
            </div>
          </ResizableBox>
        </Draggable>
      )}

      {/* Modal for Compare */}
      {isCompareModalOpen && (
        <Draggable>
          <ResizableBox
            width={500}
            height={400}
            minConstraints={[300, 200]}
            maxConstraints={[1000, 800]}
            className="modal modal-right"
          >
            <div className="modal-content">
              <h2>Job Description Comparison</h2>
              {compareData ? (
                <table className="compare-table">
                  <thead>
                    <tr>
                      <th>Criteria</th>
                      <th>Original JD</th>
                      <th>Revised JD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compareData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.criteria}</td>
                        <td>{item["original JD"]}</td>
                        <td>{item["revised JD"]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>Loading...</div>
              )}
              <button onClick={() => setCompareModalOpen(false)}>Close</button>
            </div>
          </ResizableBox>
        </Draggable>
      )}
      <JDDiffChecker />
    </div>
  );
}

export default JobDetail;