import React, { useState } from "react";
import { diffWords } from "diff"; // Import the specific function

function JDDiffChecker() {
  const [originalJD, setOriginalJD] = useState("");
  const [revisedJD, setRevisedJD] = useState("");
  const [diffResult, setDiffResult] = useState(null);

  const handleCheckDifferences = () => {
    const diff = diffWords(originalJD, revisedJD);
    setDiffResult(diff);
  };

  return (
    <div className="difference-checker">
      <h2>Job Description Difference Checker</h2>

      <div className="input-group">
        <textarea
          placeholder="Enter Original Job Description"
          value={originalJD}
          onChange={(e) => setOriginalJD(e.target.value)}
        />
        <textarea
          placeholder="Enter Revised Job Description"
          value={revisedJD}
          onChange={(e) => setRevisedJD(e.target.value)}
        />
      </div>

      <button onClick={handleCheckDifferences}>Check Differences</button>

      {diffResult && (
        <div className="diff-result">
          {diffResult.map((part, index) => (
            <span
              key={index}
              style={{
                backgroundColor: part.added
                  ? "lightgreen"
                  : part.removed
                  ? "salmon"
                  : "transparent",
              }}
            >
              {part.value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

export default JDDiffChecker;
